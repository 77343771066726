/*
 * @Description: AKJERRT
 * @Date: 2022-09-14 09:43:15
 * @LastEditTime: 2022-09-14 15:45:45
 * @FilePath: \linkgap-front\src\views\aarget_budget\components\index.js
 */
export const columns = [
  {
    dataIndex: 'name',
    width: '25%',
    align: 'center'
  },
  {
    dataIndex: 'value',
    width: '15%',
    scopedSlots: { customRender: 'value' },
    align: 'center'
  },
]